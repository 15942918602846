const path = require("path");

module.exports = {
  i18n: {
    locales: ["default", "en"],
    defaultLocale: "default",
    localeDetection: false,
  },
  trailingSlash: true,
  // fallbackLng: {
  //   default: ["en"],
  // },
  reloadOnPrerender: process.env.NODE_ENV !== "production",
  localePath: path.resolve("./public/locales"),
};
