import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useController, Control } from "react-hook-form";

interface CustomSelectProps {
  name: string;
  control: Control<any>;
  options: { value: string; label: string }[];
  placeholder?: string;
  className?: string;
  error?: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  control,
  options,
  placeholder,
  className,
  error,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: placeholder || "",
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    //click outside
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement && !e.target.closest(".custom-select")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleSelect = (selectedValue: string) => {
    onChange(options.find((option) => option.value === selectedValue)?.label);
    setIsOpen(false);
  };

  return (
    <div className={classNames("custom-select relative", className)}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="placeholder:text-light flex w-full cursor-pointer items-center justify-between bg-site-background-gray5 px-6 py-3 text-site-text-16-24">
        {value && options.find((option) => option.label === value) ? (
          <span>{value}</span>
        ) : (
          <span className="text-site-text-16-24 font-light text-site-background-gray6">{placeholder}</span>
        )}
        <i
          className={classNames(
            "icon-arrowDown text-[12px] transition-all duration-300",
            isOpen ? "rotate-180 transform" : "rotate-0 transform"
          )}></i>
      </div>
      {isOpen && (
        <div className="absolute left-0 top-full z-10 mt-2 w-full overflow-hidden rounded-md bg-white shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleSelect(option.value)}
              className="cursor-pointer px-3 py-2 text-site-text-16-24 transition-all duration-300 hover:bg-gray">
              {option.label}
            </div>
          ))}
        </div>
      )}
      {/* {error && <ErrorMsg errorMsg={error} />} */}
    </div>
  );
};
