import classNames from "classnames";
import React from "react";

interface LoaderTypes {
  classImageWrapper?: string;
  classContainerWrapper?: string;
}
export const Loader = ({ classImageWrapper, classContainerWrapper }: LoaderTypes) => {
  return (
    <div className={classNames("absolute bottom-0 left-0 right-0 top-0 bg-white/60", classContainerWrapper)}>
      <div
        className={classNames(
          "absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4",
          classImageWrapper
        )}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <radialGradient id="a8" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
            <stop offset="0" stopColor="#FF790C"></stop>
            <stop offset=".3" stopColor="#FF790C" stopOpacity=".9"></stop>
            <stop offset=".6" stopColor="#FF790C" stopOpacity=".6"></stop>
            <stop offset=".8" stopColor="#FF790C" stopOpacity=".3"></stop>
            <stop offset="1" stopColor="#FF790C" stopOpacity="0"></stop>
          </radialGradient>
          <circle
            transform-origin="center"
            fill="none"
            stroke="url(#a8)"
            strokeWidth="18"
            strokeLinecap="round"
            strokeDasharray="200 1000"
            strokeDashoffset="0"
            cx="100"
            cy="100"
            r="70">
            <animateTransform
              type="rotate"
              attributeName="transform"
              calcMode="spline"
              dur="2"
              values="360;0"
              keyTimes="0;1"
              keySplines="0 0 1 1"
              repeatCount="indefinite"></animateTransform>
          </circle>
          <circle
            transform-origin="center"
            fill="none"
            opacity=".2"
            stroke="#FF790C"
            strokeWidth="18"
            strokeLinecap="round"
            cx="100"
            cy="100"
            r="70"></circle>
        </svg>
      </div>
    </div>
  );
};
