export const menuItemsHeader = [
  {
    label: "Solutions",
    route: "",
    name: "",
    children: [
      { label: "SimpleMSK", route: "/SimpleMSK", name: "simpleMSK" },
      { label: "SimpleEAP", route: "/SimpleEAP", name: "simpleEAP" },
      { label: "SimpleWellbeing", route: "/SimpleWellbeing", name: "simpleWellbeing" },
      { label: "SimpleBehavioral", route: "/SimpleBehavioral", name: "simpleBehavioral" },
    ],
  },
  {
    label: "Company",
    route: "",
    name: "",
    children: [
      { label: "About", route: "/about", name: "about" },
      {
        label: "Diversity, Equity, & Inclusion",
        route: "/diversity-equity-inclusion",
        name: "diversityEquityInclusion",
      },
      { label: "Careers", route: "/careers", name: "careers" },
      { label: "Testimonials", route: "/testimonials", name: "testimonials" },
      { label: "Press and Media", route: "/press-and-publications", name: "Press and Media" },
      { label: "Contact Us", route: "/contact", name: "contact" },
    ],
  },
  {
    label: "Providers",
    route: "/providers",
    name: "providers",
  },
  {
    label: "Resources",
    route: "/blog",
    name: "Resources",
    children: [
      { label: "Security", route: "/security-page", name: "securityPage" },
      {
        label: "Validated Research and Studies",
        route: "/validated-research-and-studies",
        name: "Validated Research and Studies",
      },
      { label: "Terms of Service", route: "/terms-conditions", name: "termsConditions" },
      {
        label: "Informed Consent of Telehealth",
        route: "/informed-telehealth-consent",
        name: "informedTelehealthConsent",
      },
      { label: "Privacy Policy", route: "/privacy-policy", name: "privacyPolicy" },
      { label: "Accessibility Statement", route: "/accessibility-statement", name: "accessibilityStatement" },
    ],
  },
];
export const menuItemsFooter = [
  {
    label: "Solutions",
    route: "",
    name: "",
    children: [
      { label: "SimpleMSK", route: "/SimpleMSK", name: "simpleMSK" },
      { label: "SimpleEAP", route: "/SimpleEAP", name: "simpleEAP" },
      { label: "SimpleWellbeing", route: "/SimpleWellbeing", name: "simpleWellbeing" },
      { label: "SimpleBehavioral", route: "/SimpleBehavioral", name: "simpleBehavioral" },
      {
        label: "For Members",
        route: "",
        name: "",
        hide: "mobile",
        children: [
          {
            label: "Member Login",
            route: "/member-login",
            name: "memberLogin",
          },
        ],
      },
    ],
  },

  {
    label: "Company",
    route: "",
    name: "",
    children: [
      { label: "About", route: "/about", name: "about" },
      {
        label: "Diversity, Equity, & Inclusion",
        route: "/diversity-equity-inclusion",
        name: "diversityEquityInclusion",
      },
      { label: "Testimonials", route: "/testimonials", name: "testimonials" },
      { label: "Careers", route: "/careers", name: "careers" },
      { label: "Press and Media", route: "/press-and-publications", name: "Press and Media" },
      { label: "Contact Us", route: "/contact", name: "contact" },
    ],
  },

  {
    label: "Resources",
    route: "",
    name: "",
    children: [
      {
        label: "Providers",
        route: "/providers",
        name: "providers",
      },
      {
        label: "Blog",
        route: "/blog",
        name: "Resources",
      },
      { label: "Security", route: "/security-page", name: "securityPage" },
      {
        label: "Validated Research and Studies",
        route: "/validated-research-and-studies",
        name: "Validated Research and Studies",
      },
      { label: "Terms of Service", route: "/terms-conditions", name: "termsConditions" },
      {
        label: "Informed Consent of Telehealth",
        route: "/informed-telehealth-consent",
        name: "informedTelehealthConsent",
      },
      { label: "Privacy Policy", route: "/privacy-policy", name: "privacyPolicy" },
      { label: "Accessibility Statement", route: "/accessibility-statement", name: "accessibilityStatement" },
    ],
  },
  {
    label: "For Members",
    route: "",
    name: "",
    hide: "desktop",
    children: [
      {
        label: "Member Login",
        route: "/member-login",
        name: "memberLogin",
      },
    ],
  },
];
