import DefaultLayout from "@/components/site/Layouts/DefaultLayout";
import "@/fonts/icomoon/style.css";
import "@/styles/site/globals.css";
import { SiteProvider } from "@/utils/siteContext";
import { appWithTranslation } from "next-i18next";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";

import nextI18nConfig from "../../next-i18next.config";

function MyApp({ Component, pageProps }: AppProps) {
  const getLayout =
    (Component as any).getLayout || ((page: React.ReactNode) => <DefaultLayout>{page}</DefaultLayout>);

  return <SiteProvider>{getLayout(<Component {...pageProps} />)}</SiteProvider>;
}

export default appWithTranslation(MyApp, {
  ...nextI18nConfig,
  i18n: {
    ...nextI18nConfig.i18n,
    localeDetection: false,
  },
});

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric);
}
