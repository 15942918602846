import MenuDropdown from "@/components/site/Header/MenuDropdown";
import { getRoute } from "@/utils/navigationUtils";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useRef, useState, useEffect } from "react";

const MenuItem = ({
  item,
  pageName,
  setPageName,
  open,
  setOpen,
  toggleMenu,
  idx,
  hasChildren,
  locale,
  slugs,
  isMobile,
}: any) => {
  const ref = useRef<HTMLLIElement>(null);
  const [itemOpen, setItemOpen] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);

  const handleClick = () => {
    open && setOpen(!open);
  };

  // useEffect(() => {
  //   // resize observer
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     // entries.forEach((entry) => {
  //     // });
  //     setIsMobile(entries[0].contentRect.width < 940);
  //   });
  //   if (header) {
  //     resizeObserver.observe(header);
  //   }
  //   return () => resizeObserver.disconnect();
  // }, [header]);
  const pathname = usePathname();

  const isActive = (item: any) => {
    if (item.route === pathname) return true;
    if (item.children) {
      return item.children.some((child: any) => isActive(child));
    }
    return false;
  };

  return (
    <>
      <li
        {...(isMobile ? { onClick: () => setItemOpen(!itemOpen) } : {})}
        className={classNames(
          "group relative flex flex-col items-center justify-center border-b-[1px] border-stroke p-4 md2:border-none md2:p-0",
          idx === 0 ? "border-t-[1px] md2:border-none" : "",
          hasChildren && itemOpen ? "bg-site-background-white" : "",
          isMobile ? "mobile" : "desktop"
        )}
        ref={ref}>
        {item.name ? (
          <div className="flex h-full w-full items-center justify-between gap-10 md2:gap-2">
            {/* getRoute(item.name, slugs, locale) */}
            <Link
              href={getRoute(item.name, slugs, locale)}
              onClick={handleClick}
              area-label={item.label}
              data-route={getRoute(item.name, slugs, locale)}
              className={classNames(
                "ransition-all group relative z-[55] flex h-full w-full items-center justify-between text-center text-site-text-18-21 font-light text-site-text-blue-1 transition-all duration-300 ease-in-out before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-700 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-700 after:ease-in-out md2:w-fit md2:text-site-text-16-24 md2:hover:before:w-[50%] md2:hover:after:w-[50%]"
              )}>
              <span>{item.label}</span>
            </Link>
            {item.children && (
              <button
                className="flex h-full items-center justify-center"
                {...(isMobile ? { onClick: () => setItemOpen(!itemOpen) } : {})}
                area-label={item.label}>
                <i
                  className={classNames(
                    "icon-arrowDown text-[14px] text-site-text-blue-1 transition-all duration-300 md2:group-hover:rotate-180",
                    itemOpen ? "rotate-180" : ""
                  )}></i>
              </button>
            )}
          </div>
        ) : (
          <p
            className={classNames(
              "ransition-all group relative z-[55] flex h-full w-full cursor-pointer items-center justify-between gap-2 text-center text-site-text-18-21 font-light text-site-text-blue-1 transition-all duration-300 ease-in-out before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-700 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-700 after:ease-in-out md2:w-fit md2:text-site-text-16-24 md2:hover:cursor-pointer md2:hover:before:w-[50%] md2:hover:after:w-[50%]",
              itemOpen ? "font-medium" : ""
            )}>
            <span>{item.label}</span>
            {item.children && (
              <i
                className={classNames(
                  "icon-arrowDown text-[14px] text-site-text-blue-1 transition-all duration-300 md2:group-hover:rotate-180",
                  itemOpen ? "rotate-180" : ""
                )}></i>
            )}
          </p>
        )}
        {item.children && (
          <div
            className={classNames(
              "z-50 grid w-full grid-rows-[0fr] transition-all duration-300 md2:invisible md2:absolute md2:right-0 md2:top-[24px] md2:w-max md2:pt-12 md2:opacity-0 md2:group-hover:visible md2:group-hover:grid-rows-[1fr] md2:group-hover:opacity-100",
              itemOpen ? "grid-rows-[1fr]" : ""
            )}>
            <div className="min-h-0 overflow-hidden rounded-md md2:bg-site-background-white md2:shadow-site-nav">
              <MenuDropdown
                item={item.children}
                open={open}
                setOpen={setOpen}
                slugs={slugs}
                locale={locale}
              />
            </div>
          </div>
        )}
      </li>
    </>
  );
};

export default MenuItem;
