import Link from "next/link";
import React from "react";

const SocialLinks = () => {
  return (
    <div className="justify-star flex flex-row">
      <Link
        href="https://www.linkedin.com/company/simpletherapy/posts/?feedView=all"
        className="group"
        target="_blank"
        aria-label="LinkedIn"
        title="Link to LinkedIn">
        <i className="icon-linkid mr-6 h-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>
      </Link>
      <Link
        href="https://www.instagram.com/simpletherapy"
        className="group"
        target="_blank"
        aria-label="Instagram"
        title="Link to Instagram">
        <i className="icon-insta mr-6 h-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>
      </Link>
      <Link
        href="https://www.facebook.com/simpletherapy"
        className="group"
        target="_blank"
        aria-label="Facebook"
        title="Link to Facebook">
        <i className="icon-fb mr-6 h-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>
      </Link>
    </div>
  );
};

export default SocialLinks;
