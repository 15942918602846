import MenuItem from "@/components/site/Header/MenuItem";
import { menuItemsHeader } from "@/components/site/consts/navigate";
// import { LanguageSwitcher } from "@/components/site/parts/LanguageSwitcher";
import useLocalStorage from "@/lib/hooks/useLocalStorage";
import { getRoute } from "@/utils/navigationUtils";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import { Trans } from "next-i18next";
import { i18n } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useState, useRef, useEffect } from "react";

export const Header = () => {
  const pathname = usePathname();
  const ref = useRef<HTMLLIElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const { setOpenModal, setClosingModal, slugs } = useSiteContext();
  const [open, setOpen] = useState(false);
  const locale = i18n && i18n.language;
  useEffect(() => {
    //click outside
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // resize observer
    const resizeObserver = new ResizeObserver((entries) => {
      // entries.forEach((entry) => {
      // });
      const mobile = entries[0].contentRect.width < 913;
      setIsMobile(mobile);
      if (!mobile) {
        setOpen(false);
      }
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, [ref.current]);

  const eventHandler = () => {
    return open && setOpen(!open);
  };
  // const handleScroll = () => {
  //   if (window.scrollY > 0) {
  //     setIsScrollToBottom(true);
  //   } else {
  //     setIsScrollToBottom(false);
  //   }
  // };
  // const switchLocale = () => {

  //   const { locale, pathname, query, asPath } = router;
  //   const nextLocale = locale === "en" ? "es" : "en";
  //   if (locale === "en") {
  //     i18n.changeLanguage("es");
  //     router.push({ pathname, query }, asPath, { locale: nextLocale });
  //   } else {
  //     i18n.changeLanguage("en");
  //     router.push({ pathname, query }, asPath, { locale: nextLocale });
  //   }

  // };
  const toggleMenu = () => {
    setOpen(!open);
  };
  const handleOpenModal = () => {
    if (pathname?.includes("contact")) {
      return;
    }
    setOpenModal(true);
    setClosingModal(false);
  };
  // const pathname = usePathname();
  const [pageName, setPageName] = useLocalStorage("selectedMenu", "dashboard");

  const getSlugFromHrefWithoutLocale = (href: string, locale: string) => {
    const hrefWithoutLocale = href.replace(`/${locale}`, "");
    const slug = hrefWithoutLocale.split("/").pop();
    return slug || "";
  };
  return (
    <header
      ref={ref}
      className={classNames(
        "container fixed left-2/4 top-0 z-99 mx-auto w-full -translate-x-2/4 pb-4 pt-4 transition-all duration-300 md:pb-12 md:pt-[15px] xl:max-w-[91%]"
        // open ? "min-h-fit !p-0" : ""
      )}>
      <nav
        className={classNames(
          "flex w-full flex-wrap items-center justify-between bg-white px-0 py-0 shadow-site-nav md3:px-4 md2:flex-nowrap md2:rounded-full md2:py-4 lg:px-7 xl:px-10.5",
          open ? "flex-col rounded-[20px] bg-white shadow-none" : "rounded-full",
          isMobile ? "modile" : "desktop"
        )}>
        <div className="flex w-full flex-row justify-between px-[1.5rem] py-4 md2:w-auto md2:px-0 md2:py-0">
          <Link
            className={classNames("mr-6 flex self-center")}
            aria-label="SimpleTherapy"
            title="SimpleTherapy"
            href="/">
            <Image
              src="/images/logo/SimpleTherapy.svg"
              className="max-h-[1.3rem] w-auto md2:max-h-6"
              alt="logo SimpleTherapy"
              width="157"
              height="24"
            />
          </Link>
          {/* <LanguageSwitcher seOpen={setOpen} /> */}
          <div className="flex items-center justify-center md2:hidden">
            <button
              className={classNames(
                "relative h-4 w-4 before:absolute before:left-0 before:top-0 before:h-0.5 before:w-full before:transform before:rounded-[2px] before:bg-current before:transition-all before:duration-500 before:ease-in-out after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:transform after:rounded-[2px] after:bg-current after:transition-all after:duration-500 after:ease-in-out focus:outline-none",
                open
                  ? "before:translate-y-[7px] before:rotate-45 after:-translate-y-[7px] after:-rotate-45"
                  : ""
              )}
              aria-label="Burger Menu Button"
              onClick={toggleMenu}>
              <span
                className={classNames(
                  "absolute left-0 top-2/4 block h-0.5 w-full -translate-y-2/4 transform rounded-[2px] bg-current transition duration-500 ease-in-out",
                  open ? "opacity-0" : ""
                )}></span>
              {/* <span
                className={classNames(
                  "absolute block h-0.5 w-4 transform bg-current text-site-text-blue-2 transition duration-500 ease-in-out",
                  open ? "rotate-45" : "-translate-y-1.5"
                )}></span>
              <span
                className={classNames(
                  "absolute block h-0.5 w-4 transform bg-current text-site-text-blue-2 transition duration-500 ease-in-out",
                  open ? "opacity-0" : ""
                )}></span>
              <span
                className={classNames(
                  "absolute block h-0.5 w-4 transform bg-current text-site-text-blue-2 transition duration-500 ease-in-out",
                  open ? "-rotate-45" : "translate-y-1.5"
                )}></span> */}
            </button>
          </div>
        </div>

        <div
          className={classNames(
            "block h-0 w-full flex-grow overflow-hidden transition-height duration-500 md2:flex md2:h-auto md2:w-auto md2:items-center md2:overflow-visible",
            open ? "!h-auto" : ""
          )}>
          {/* <Link
            className={classNames("flex justify-center self-center", open ? "block md2:hidden" : "hidden")}
            href="/">
            <Image src="/images/logo/SimpleTherapy.svg" alt="logo" width="157" height="24" />
          </Link> */}
          <ul className="flex flex-grow flex-col justify-center gap-0 text-sm md2:flex-row md2:gap-2 lg:gap-6">
            {menuItemsHeader.map((menuItem, menuIndex) => (
              <MenuItem
                key={menuIndex}
                idx={menuIndex}
                item={menuItem}
                pageName={pageName}
                setPageName={setPageName}
                toggleMenu={toggleMenu}
                open={open}
                setOpen={setOpen}
                isMobile={isMobile}
                hasChildren={menuItem.children && menuItem.children.length > 0}
                locale={locale}
                slugs={slugs}
              />
            ))}
          </ul>
          <div className="flex w-full flex-col justify-center p-4 md2:w-fit md2:flex-row md2:p-0">
            <Link
              onClick={eventHandler}
              href={getRoute("memberLogin", slugs, locale || "en")}
              area-label={"Member Login"}
              title="Link to Member Login"
              className={classNames(
                "group relative order-2 flex items-center justify-center gap-2.5 rounded-md p-4 text-site-text-18-21 font-light md2:order-1 md2:justify-start md2:p-0 md2:text-site-text-14-21"
              )}>
              <i
                className={classNames(
                  "icon-user_login font-bold transition-all duration-300 group-hover:text-site-btn-orange",
                  pathname &&
                    pathname.includes(
                      getSlugFromHrefWithoutLocale(
                        getRoute("memberLogin", slugs, locale || "en"),
                        locale || "en"
                      )
                    )
                    ? "text-site-btn-orange"
                    : ""
                )}></i>
              <span
                className={classNames(
                  "relative transition-all ease-in-out before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-700 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-700 after:ease-in-out hover:before:w-[50%] hover:after:w-[50%]",
                  pathname &&
                    pathname.includes(
                      getSlugFromHrefWithoutLocale(
                        getRoute("memberLogin", slugs, locale || "en"),
                        locale || "en"
                      )
                    )
                    ? "font-medium text-site-btn-orange before:w-[50%] after:w-[50%]"
                    : ""
                )}>
                <Trans i18nKey={"menu.Member Login"} defaults="" />
              </span>
            </Link>
            <button
              onClick={handleOpenModal}
              aria-label="Comtact Us"
              className="group order-1 mt-4 inline-flex w-full justify-center rounded-[48px] bg-site-btn-orange px-14 py-3 text-center text-site-text-16-23 leading-none text-site-text-white transition-all duration-300 hover:bg-site-btn-orange-hover md2:order-2 md2:ml-4 md2:mt-0 md2:w-auto md2:py-[18px]">
              <Trans i18nKey="menu.Try Demo" defaults="" />
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};
