"use client";

import { Footer } from "@/components/site/Footer";
import { Header } from "@/components/site/Header";
import { ContactsUsForm } from "@/components/site/parts/ContactUsForm";
import { Modal } from "@/components/site/parts/Modal";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { usePathname } from "next/navigation";
import React from "react";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const path = usePathname();
  const { t } = useTranslation("common");
  return (
    <>
      <Header />

      <main
        className={classNames(
          "lg:min-h-[calc(100vh_-_772px)]",
          path === "/" ? "" : "pt-[106px] md:pt-[200px]",
          path === "/testimonials" ? "bg-site-background-gray" : ""
        )}>
        <div>{children}</div>
      </main>
      <Modal classModal="max-w-[95%] sm:max-w-[80%] xl:max-w-[50%] w-full max-h-fit h-[98%] sm:h-auto sm:max-h-[99%] rounded-lg bg-white p-4 md:p-8">
        <h3 className="mb-2 sm:mb-4 md:mb-8">
          <span className="relative mr-2 inline-flex w-fit text-site-text-32-38 font-normal capitalize text-site-text-blue-1 before:absolute before:bottom-[-5px] before:right-[0px] before:z-[0] before:h-[11px] before:w-full before:bg-[url('/images/site/ContactUs/decor_line.svg')] before:bg-cover before:bg-no-repeat md:text-site-text-56-67 md:before:bottom-[-10px] md:before:h-[19px]">
            {t("contactUs.title.lineDecoration-1") || "Contact"}
          </span>
          <span className="relative mr-2 inline w-fit text-site-text-32-38 text-site-text-blue-1 before:absolute before:right-[-23px] before:top-0.5 before:h-[20px] before:w-[28px] before:bg-[url('/images/site/ContactUs/decor.svg')] before:bg-contain before:bg-no-repeat md:text-site-text-56-67 md:before:top-2 md:before:h-[25px]">
            {t("contactUs.title.lineDecoration-2") || "Us"}
          </span>
        </h3>
        <ContactsUsForm fieldsSettings={{ textAria: { rows: 2 } }} />
      </Modal>
      <Footer></Footer>
    </>
  );
}
