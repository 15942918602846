import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

export const ThankYou = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <Image
          src={`/images/site/ThankYouPage/open-text.svg`}
          height={67}
          width={67}
          alt="Thank You"
          className="mb-5 md:mb-10"
        />
        <p className="mb-5 text-center text-site-text-28-33 font-bold md:text-site-text-33-38">
          {t("thankYouPage.title")}
        </p>
        <p className="text-center text-site-text-16-20 font-light md:text-site-text-18-27">
          {t("thankYouPage.description")}
        </p>
      </div>
    </div>
  );
};
