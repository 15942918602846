//Context
import React, { createContext, useContext, useState, useEffect } from "react";

const SiteContext = createContext<any | undefined>(undefined);

export const SiteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [closingModal, setClosingModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [filtredPosts, setFiltredPosts] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  interface Slug {
    id: number;
    name: string;
    translations: {
      locale: string;
      slug: string;
    }[];
  }

  const [slugs, setSlugs] = useState<Slug[]>([]);
  useEffect(() => {
    const fetchSlugs = async () => {
      const response = await fetch("/api/slugs");
      const data = await response.json();
      setSlugs(data);
    };
    fetchSlugs();
  }, []);
  const value = {
    openModal,
    setOpenModal,
    closingModal,
    setClosingModal,
    posts,
    setPosts,
    filtredPosts,
    setFiltredPosts,
    isLoader,
    setIsLoader,
    slugs,
    setSlugs,
  };

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error("useSite must be used within a SiteProvider");
  }
  return context;
};
