import classNames from "classnames";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

interface MenuSectionProps {
  item: { label: string; children?: Children[] };
  index: number;
  isChild?: boolean;
  device?: string;
  isMobile?: boolean;
}

interface Children {
  label: string;
  route: string;
  name: string;
  hide?: string;
  children?: Children[];
}

const MenuSection = ({ item, index, isChild, device, isMobile }: MenuSectionProps) => {
  const pathname = usePathname();

  return (
    <div
      className={classNames(
        "mb-4.5 flex basis-[100%] flex-col self-start pt-4.5 sm:mb-6 sm:basis-[33%] sm:pt-0 md:basis-[20%]",
        index > 0 && !isChild
          ? "border-t-[1px] border-t-site-border-white sm:border-l-[1px] sm:border-t-[0px] sm:border-l-site-border-white sm:pl-6"
          : "",
        isChild ? "mt-5" : " "
      )}>
      <div className="mb-2 text-site-text-20-26 font-semibold text-site-text-white">{item.label}</div>
      {item.children &&
        item.children.map((child, index) =>
          child?.hide === device ? null : child?.children ? (
            <MenuSection key={index} item={child} index={index} isChild={true} device={device} />
          ) : (
            <Link
              key={index}
              className={classNames(
                "relative mb-1 w-fit text-site-text-16-24 font-extralight transition-all duration-100 before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-300 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-300 after:ease-in-out hover:text-site-btn-orange hover:before:w-[50%] hover:after:w-[50%]",
                pathname && pathname.includes(child.route)
                  ? "text-site-btn-orange before:w-[50%] after:w-[50%]"
                  : "text-site-text-white"
              )}
              href={child.route}>
              {child.label}
            </Link>
          )
        )}
    </div>
  );
};

export default MenuSection;
