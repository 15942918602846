import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";

interface Option {
  value: string;
  text: string;
  selected: boolean;
}

const MultiSelect = ({
  id,
  title,
  dataList,
  value,
  onChange,
  placeholder,
}: {
  id: string;
  title: string;
  dataList: any[];
  value: number[];
  onChange?: any;
  placeholder?: string;
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<any>(null);
  const trigger = useRef<any>(null);

  useEffect(() => {
    const loadOptions = () => {
      const select = document.getElementById(id) as HTMLSelectElement | null;
      if (select) {
        const newOptions: Option[] = [];
        dataList?.map((item, index) => {
          newOptions.push({
            value: item.hasOwnProperty("id") ? item.id : (index + 1).toString(),
            text: item.hasOwnProperty("name") ? item.name : (index + 1).toString(),
            selected: value.includes(item.id),
          });
        });
        setOptions(newOptions);
      }
    };
    loadOptions();
  }, [id, dataList, value]);

  const open = () => setShow(true);

  const isOpen = () => show === true;

  const select = (item: string) => {
    const newValues = value.includes(Number(item))
      ? value.filter((option) => option.toString() != item)
      : [...value, item];
    onChange(newValues);
  };

  const remove = (item: string) => {
    const newValues = value.filter((option) => option.toString() != item);
    onChange(newValues);
  };

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdownRef.current) return;
      if (!show || dropdownRef.current.contains(target) || trigger.current.contains(target)) return;
      setShow(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  return (
    <div className="relative z-50">
      {title && <label className="mb-3 block text-sm font-medium text-black dark:text-white">{title}</label>}
      <div>
        <select className="hidden" id={id}></select>

        <div className="flex flex-col items-center">
          <div className="relative z-20 inline-block w-full">
            <div className="relative flex flex-col items-center">
              <div ref={trigger} onClick={open} className="w-full">
                <div className="flex bg-site-background-gray5 px-6 py-3 outline-none transition focus:border-primary active:border-primary">
                  <div className="flex flex-auto flex-wrap gap-3">
                    {options
                      ?.filter((option) => option.selected)
                      ?.map((option) => (
                        <div
                          key={option.value}
                          className="flex items-center justify-center rounded border-[.5px] border-stroke bg-gray px-2.5 py-1.5 text-sm font-medium dark:border-strokedark dark:bg-white/30">
                          <div className="max-w-full flex-initial">{option.text}</div>
                          <div className="flex flex-auto flex-row-reverse">
                            <div
                              onClick={() => remove(option.value)}
                              className="cursor-pointer pl-2 hover:text-danger">
                              <svg
                                className="fill-current"
                                role="button"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z"
                                  fill="currentColor"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    {options?.filter((option) => option.selected).length === 0 && (
                      <div className="flex flex-1 self-center">
                        {/* <input
                          placeholder={placeholder || "Select an option"}
                          className="placeholder:text-light h-full w-full appearance-none bg-transparent outline-none placeholder:text-site-text-16-24"
                          defaultValue=""
                        /> */}
                        <div className="text-site-text-16-24 font-light text-site-background-gray6">
                          {placeholder || "Select an option"}
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={open}
                    className={classNames(
                      "flex transform cursor-pointer items-center outline-none transition-all duration-300 focus:outline-none",
                      isOpen() ? "rotate-180" : "rotate-0"
                    )}>
                    <i className={classNames("icon-arrowDown text-[12px]")}></i>
                  </button>
                </div>
              </div>
              <div className="w-full px-4">
                <div
                  className={`max-h-select absolute left-0 top-full z-40 mt-2 w-full overflow-y-auto rounded bg-white shadow-lg dark:bg-form-input ${
                    isOpen() ? "" : "hidden"
                  }`}
                  ref={dropdownRef}
                  onFocus={() => setShow(true)}
                  onBlur={() => setShow(false)}>
                  <div className="flex w-full flex-col">
                    {options.map((option, index) => (
                      <div key={index}>
                        <div className="w-full cursor-pointer" onClick={(event) => select(option.value)}>
                          <div
                            className={`relative flex w-full items-center ${
                              option.selected ? "border-primary" : ""
                            }`}>
                            <div className="flex w-full items-center">
                              <div className="w-full cursor-pointer px-3 py-2 text-site-text-16-24 transition-all duration-300 hover:bg-gray">
                                {option.text}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
