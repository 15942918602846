import { menuItemsFooter } from "@/components/site/consts/navigate";
import SocialLinks from "@/components/site/parts/SocialLinks";
// import { getRoute } from "@/utils/navigationUtils";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import parse from "html-react-parser";
import { Trans } from "next-i18next";
// import { i18n } from "next-i18next";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React, { useRef, useEffect } from "react";

import MenuSection from "./MenuSection";

export const Footer = () => {
  const { t } = useTranslation("common");
  const footerRef = useRef<HTMLLIElement>(null);
  const [isMobile, setIsMobile] = React.useState(false);
  useEffect(() => {
    // resize observer
    const resizeObserver = new ResizeObserver((entries) => {
      // entries.forEach((entry) => {
      // });
      setIsMobile(entries[0].contentRect.width < 640);
    });
    if (footerRef.current) {
      resizeObserver.observe(footerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [footerRef]);

  const device = isMobile ? "mobile" : "desktop";
  return (
    <footer ref={footerRef} className={classNames("sticky top-[100vh] w-full bg-site-background-blue py-16")}>
      <div className="container flex flex-col">
        <div className="mb-6 flex flex-wrap justify-between">
          <div className="mb-6 flex basis-[100%] flex-col self-start pr-16 md:mb-5 md:basis-[40%]">
            <Image
              className="mb-2"
              src="/images/logo/SimpleTherapyWhite.svg"
              alt="Simple Therapy"
              width="235"
              height="36"
            />
            <div className="mb-4 text-site-text-16-20 font-semibold text-site-text-white">
              <Trans i18nKey="footer.Where improved health meets simplicity." defaults="" />
            </div>
            <div className="mb-2 text-site-text-16-24 font-normal text-site-text-white">
              <Link
                className="group relative flex w-fit flex-row items-center"
                aria-label="Phone"
                title="Phone: 1-800-644-2478"
                href={`tel:1-800-644-2478`}>
                <i className="icon-phone_f mr-3 w-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>
                <span className="relative before:absolute before:bottom-0 before:left-[50%] before:h-[1px] before:w-0 before:origin-center before:bg-site-btn-orange before:transition-[width] before:duration-300 before:ease-in-out after:absolute after:bottom-0 after:right-[50%] after:h-[1px] after:w-0 after:origin-center after:bg-site-btn-orange after:transition-[width] after:duration-300 after:ease-in-out group-hover:text-site-btn-orange group-hover:before:w-[50%] group-hover:after:w-[50%]">
                  1-800-644-2478
                </span>
              </Link>
            </div>
            <div className="mb-2 flex flex-row items-center text-site-text-16-24 font-normal text-site-text-white">
              <i className="icon-location_f mr-3 w-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>

              <Trans i18nKey="footer.1080 W Shaw Ave, Ste 105, Fresno, CA 93711" defaults="" />
            </div>
            <div className="mb-4 flex flex-row items-center text-site-text-16-24 font-normal text-site-text-white">
              <i className="icon-location_f mr-3 w-6 transition-all duration-300 group-hover:text-site-btn-orange"></i>

              <Trans i18nKey="footer.6111 Southfront Road, Ste B, Livermore, CA 94551" defaults="" />
            </div>
            <div className="text-site-text-18-27 font-normal text-site-text-white">
              <div className="mb-4">
                <Trans i18nKey="footer.Connect with us" defaults="" />
              </div>
              <SocialLinks />
            </div>
          </div>
          {menuItemsFooter.map((item, index) =>
            item?.hide === device ? null : (
              <MenuSection
                key={index}
                item={item}
                index={index}
                isChild={false}
                device={device}
                isMobile={isMobile}
              />
            )
          )}
        </div>
        <div className="mb-6 flex flex-row flex-wrap items-center justify-center gap-5 py-6 md:gap-6 md2:gap-12">
          <Image
            className="h-[50px] w-[134px] object-contain md2:h-[52px] md2:w-[158px]"
            src="/images/site/footer/SwissReFondation.png"
            alt="Swiss Re Fondation"
            width={158}
            height={52}
          />
          <Image
            className="order-3 h-[80px] w-auto object-contain sm2:order-2 md2:h-[110px] md2:w-[128px]"
            src="/images/site/footer/Sustainable Development Goals.png"
            alt="Sustainable Development Goals"
            width={128}
            height={110}
          />
          <Image
            className="order-2 h-[60px] w-auto object-contain sm2:order-3 md2:h-[79px] md2:w-[153px]"
            src="/images/site/footer/HipaaComplance.png"
            alt="Hipaa Complance"
            width={153}
            height={79}
          />
          <Image
            className="order-4 h-[80px] w-auto object-contain md2:h-[110px] md2:w-[151px]"
            src="/images/site/footer/TopVendor.svg"
            alt="Top Vendor"
            width={97}
            height={110}
          />
          <Image
            className="order-5 h-[80px] w-auto object-contain md2:h-[110px] md2:w-[110px]"
            src="/images/site/footer/AICPA-SOC.png"
            alt="AICPA SOC"
            width={110}
            height={110}
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-6 text-center text-site-text-10-11 font-normal text-site-text-white">
            <Trans i18nKey="footer.SimpleTherapy, Inc.® does not offer medical" defaults="" />
          </div>
          <div className="mb-4 text-center text-site-text-10-11 font-normal text-site-text-white">
            © {new Date().getFullYear() + " "}
            <Trans i18nKey="footer.SimpleTherapy, Inc. All Rights Reserved." defaults="" />
          </div>
          <div className="mb-4 text-center text-site-text-10-11 font-normal text-site-text-white">
            {parse(t("footer.See our Privacy Policy, Terms of Use and Informed Telehealth Consent"))}
          </div>
          <div className="mb-4 text-center text-site-text-10-11 font-normal text-site-text-white">
            <Trans
              i18nKey="footer.SimpleTherapy, Inc. is located at 1080 W Shaw Ave, Ste 105, Fresno, CA 93711 ©"
              defaults=""
            />
            {" " + new Date().getFullYear()}
          </div>
          <div className="mb-4 text-center text-site-text-10-11 font-normal text-site-text-white">
            <Link
              href={`mailto:support@simpletherapy.com`}
              aria-label="Email"
              title="Email: support@simpletherapy.com"
              className="text-xs-custom text-brand-text-white relative w-fit text-center after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full">
              support@simpletherapy.com
            </Link>
          </div>
          <div className="text-center">
            <p className="text-site-text-10-11 font-normal text-site-text-white">
              This site is protected by reCAPTCHA and the Google
              <Link href="https://policies.google.com/privacy" aria-label="google privacy">
                Privacy Policy
              </Link>{" "}
              and
              <Link href="https://policies.google.com/terms" aria-label="google terms">
                Terms of Service
              </Link>{" "}
              apply.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
